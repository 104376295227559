*,*::before,*::after{
  box-sizing: border-box;
}

body {
  margin: 0;
  background: linear-gradient(-45deg, yellow, #e73c7e, #23a6d5, lightgreen);
  animation: gradient 20s ease infinite;
  background-size: 250% 250%;
  
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.calc {
  display: grid;
  margin-top: 2rem;
  grid-template-columns: repeat(4,4rem);
  grid-template-rows: minmax(4rem,auto) repeat(5,4rem);
  justify-content: center;
}

.calc > button {
  cursor: pointer;
  font-size: 1.5rem;
  border: 1px solid white;
  outline: none;
  background-color: rgba(255,255,255,0.75);
}
.calc > button:hover , .calc > button:focus {
  background-color: rgba(255,255,255,0.9);
}

.span-2 {
  grid-column: span 2;
}

.output {
  grid-column: 1/-1;
  background-color: rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding: 0.75rem;
  word-wrap: break-word;
  word-break: break-all;
}

.output .previous-operand {
  color: rgba(255,255,255,0.75);
  font-size: 1.5rem;
}

.output .current-operand {
  color: white;
  font-size: 2rem;
}